@charset "UTF-8";

@font-face {
  font-family: "own";
  src:url("fonts/own.eot");
  src:url("fonts/own.eot?#iefix") format("embedded-opentype"),
    url("fonts/own.woff") format("woff"),
    url("fonts/own.ttf") format("truetype"),
    url("fonts/own.svg#own") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "own" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="own-"]:before,
[class*=" own-"]:before {
  font-family: "own" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.own-users:before {
  content: "\61";
}
.own-tachometer:before {
  content: "\62";
}
.own-user-groups:before {
  content: "\63";
}
