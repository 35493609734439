html,body{
  padding: 0;
  margin: 0;
  touch-action: unset;
}

.gx-layout-content{
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ant-form-extra {
  margin: -15px 0 10px 0;
  color: rgba(0,0,0,0.45);
}

.gx-popover-lang-scroll .gx-sub-popover li {
  display: flex;
  align-items: center;
}

.ant-tabs-nav .ant-tabs-tab-active {
  font-size: 16px;
}

.ant-card-head-title {
  font-size: 22px !important;

  &._no-padding-top {
    padding-top: 0;
  }
}

.gx-card {
  &--in-modal {
    border-bottom: 1px solid #e8e8e8 !important;
    border-radius: 0;
  }

  .ant-card-head-title {
    font-size: 16px !important;
  }
}

.ant-table-pagination.ant-pagination {
  margin-bottom: 5px;
}

.copyright {
  &__highlight {
    color: #fa8c16;
  }
}

/** Fix issue build antd less style. Reference https://github.com/less/less.js/issues/974 */
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.ant-modal-body {
  .gx-card {
    box-shadow: none;
  }

  .ant-card-head,
  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.user-permission {
  margin-bottom: 15px;
  padding-top: 20px;
  border-top: 1px solid #e8e8e8;

  &:first-child {
    border-top: none;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .ant-checkbox-wrapper {
    flex-basis: 50%;
    margin: 0 0 10px 0;
  }

  h5 {
    margin-bottom: 15px;
    color: #545454;
    font-weight: bold;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.ant-skeleton {
  vertical-align: middle;

  .ant-skeleton-content {
    display: block;
  }
}

.ant-calendar-picker {
  margin-bottom: 0 !important;
}

.ant-form-explain + .ant-form-extra {
  margin-top: 0;
}

.ant-modal-close-x {
  width: 42px;
  height: 42px;
  line-height: 42px;
}

.campaign-notes {
  margin-bottom: 30px;

  ol {
    li {
      margin: 10px 0;
    }
  }
}

.campaign-radio {
  .ant-radio-wrapper {
    margin: 10px 0;
  }
}

.ant-table-thead > tr > th {
  padding: 15px 6px !important;
}

.ant-table-tbody > tr > td {
  padding: 12px 6px !important;
}

.no-padding {
  .ant-comment-inner {
    padding: 0;
  }

  .ant-comment-content-detail {
    p {
      margin-bottom: 0;
    }
  }
}

// disable transition css
.ant-layout-sider,
.ant-menu,
.ant-menu-item,
.gx-avatar-row .ant-avatar,
.ant-menu-submenu .ant-menu-sub,
.ant-menu-submenu-title {
  transition: none;
}

.ant-rate-star--small {
  .anticon {
    svg {
      width: 15px;
    }
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 5px;
  }
}

.table-operations--tools {
  .ant-input-search {
    margin: 16px 0;
  }
}

.quote-text {
  margin-bottom: 20px;
  padding: 0 20px;
  color: #003366;
  font-style: italic;
  line-height: 32px;

  &__icon-first {
    margin: 0 10px 5px 0;
    transform: rotate(180deg);
  }

  &__author {
    display: block;
    margin: 5px 0 0 15px;
    font-size: 16px;
    opacity: 0.6;
    text-align: left;
  }

  ._icon {
    margin-left: 10px;
    font-size: 30px;
    vertical-align: bottom;
  }
}

.ant-layout-header {
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-justify-content: space-between;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;



  &--hide {
    margin-top: -72px;
  }

  .button-expand {
    position: absolute;
    bottom: -25px;
    right: 36px;
    height: 25px;
    line-height: 25px;
    width: 30px;
    text-align: center;
    cursor: pointer;
    z-index: 100;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 5px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

.gx-app-sidebar {
  .ant-layout-footer {
    padding: 10px 15px 8px 15px;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    color: #fff;

    .copyright {
      font-size: 11px;
    }
  }
}

.ant-form-item {
  &._error-spacing {
    .ant-form-explain {
      margin: -18px 0 10px;
    }
  }

  .ant-form-item-label {
    label {
      font-weight: 500;

      span {
        font-weight: 500;
      }
    }
  }
}

.dark-theme {
  .ant-layout-header {
    .button-expand {
      background-color: #38424B;
    }
  }

  .ant-table-footer {
    &__selected-item {
      color: #E0E0E0;
    }
  }
}

.notification-container {
  width: 220px;
  padding: 0 10px;
}

.notification {
  margin-top: 10px;
  padding: 5px 5px 8px 35px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 18px;

  &:before {
    left: 10px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px
  }

  .title {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 22px;
  }
}

.gx-card-widget {
  &._decrease-padding {
    .ant-card-body {
      padding-top: 0;
    }
  }
}

.matomo-iframe {
  min-height: calc(100vh - 195px);

  &__admin {
    min-height: calc(100vh - 145px);
  }

  &._more-height {
    .matomo-iframe {
      min-height: calc(100vh - 180px);

      &.iframe-analytic__item {
        min-height: calc(100vh - 120px);
      }
    }

    .matomo-iframe__admin {
      min-height: calc(100vh - 75px);
    }
  }

  .ant-card-head-title {
    font-size: 15px !important;
    white-space: normal;
  }
}


.iframe-analytic {
  position: relative;

  // &--admin {
  //   margin-bottom: 0;

  //   .iframe-analytic__item {
  //     min-height: calc(100vh - 180px);
  //   }
  // }

  &--blocks {
    margin-bottom: 16px;

    .ant-card-head {
      padding: 12px 12px 0 12px;
    }

    .ant-card-body {
      padding: 5px 0 !important;
    }
  }

  &__loading {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -30px 0 0 -30px
  }

  &__item {
    &._hide {
      opacity: 0;
    }
  }

  .ant-card-body {
    padding: 10px;
  }
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  padding-top: 200px !important;

  &.height300 {
    padding-top: 300px !important;

    .video-react {
      .video-react-video {
        max-height: 300px;
      }
    }
  }

  .video-react {
    .video-react-video {
      max-height: 200px;
    }
  }
}

.fixed-buttons {
  // position: fixed;
  // bottom: 0;
  // width: 100%;
  // max-width: 992px;
  // margin-left: -24px;
  // padding-top: 20px;
  background-color: #fff;
  text-align: center;
}

.button-green {
  color: #fff !important;
  background: #32cd32 !important;
  border-color: #32cd32 !important;
}

.button-dark-green {
  color: #fff !important;
  background: #198754 !important;
  border-color: #198754 !important;
}

.button-orange {
  color: #fff !important;
  background: #fa8c16 !important;
  border-color: #fa8c16 !important;
}

.button-gray {
  color: #fff !important;
  background: #6c757d !important;
  border-color: #6c757d !important;
}

.error-text {
  color: #f5222d;
}

.total-user-block {
  padding: 8px 20px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-card-meta {
  margin-top: -15px;
  margin-bottom: 30px;
}

.modal-full-height {
  min-height: 80vh;
  margin-bottom: 0;

  .ant-card-body {
    padding-bottom: 0;
  }
}

.ant-upload {
  &.ant-upload-drag {
    margin-bottom: 10px;

    p.ant-upload-drag-icon .anticon {
      font-size: 50px !important;
    }
  }

  .ant-upload-drag-icon {
    margin-bottom: 10px !important;
  }
}

.submit-note {
  margin: 0 auto 20px auto;
  color: #fa8c16;
  line-height: 20px;
  max-width: 100%;
  width: 330px;
  font-size: 13px;
}

.form-two-column {
  .ant-form-item {
    .ant-form-item-label {
      text-align: left;
    }
  }

  .ant-upload-list-picture-card {
    .ant-upload-list-picture-card-container,
    .ant-upload-list-item {
      width: 80px;
      height: 80px;
    }
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 80px;
    height: 80px;
  }
}

.ant-row {
  &.has-center-separator {
    > .ant-col {
      &:first-child {
        border-right: 1px solid #ddd;
      }
    }
  }
}

.highlight-note {
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: 12px;

  span {
    display: block;
    color: #fa8c16;
    line-height: 18px;
  }
}

.normal-link {
  color: #545454;
}

.error-text {
  color: #f5222d;
  line-height: 20px;
  font-size: 13px;
}

.form-item-cover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
}

.table-small-content {
  .ant-table-title {
    padding: 10px 8px !important;
    font-size: 13px;
  }

  .ant-table-tbody > tr > td {
    padding: 5px 8px !important;
    font-size: 13px !important;
  }

  .ant-table-thead > tr > th {
    padding: 15px 8px !important;
  }
}

.radio-three-cols {
  display: flex;
  flex-wrap: wrap;

  .ant-radio-wrapper {
    flex: 1;
    min-width: 33.3333%;
    margin: 0 0 15px 0;
  }
}

.select-block-small-option {
  font-size: 13px;
}

.upload-subtitle-table {
  .ant-upload-list-item {
    height: auto;

    .ant-upload-list-item-name {
      max-width: 200px;
      white-space: normal;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .ant-upload-select {
    .ant-btn {
      margin-bottom: 0;
    }
  }
}

.ant-radio-margin {
  .ant-radio-wrapper {
    margin-bottom: 10px;
  }
}

.cms-tabs .ant-tabs-nav .ant-tabs-tab {
  margin-right: 0px;
  padding: 8px 12px;

  &:hover {
    color: #fa8c16;
  }
}

.cms-tabs .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important;
}

.cms-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #fa8c16;
  border-radius: 5px 5px 0 0;
  border: none;
  color: #fff;
  font-weight: normal;
  font-size: 14px;

  &:hover {
    color: #fff;
  }
}

.video-creator-info{
  .video-creator-info-col{
    padding: 5px 0px;
    .title{
        font-size: 15px;
        width: auto;
        min-width: 100px;
        display: inline-block;
    }
    .value{
        font-size: 14px;
    }
    .avatar{
      padding: 10px 0px 0px 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      .ant-upload-picture-card-wrapper{
        width: unset;
      }
    }
  }
  .creator-social-profiles{
    padding: 5px 0px;
    &-title{
      font-size: 15px;
      padding: 5px 0px;
    }
    &-title:first-letter{
      text-transform: uppercase;
    }
    &-link{
      font-size: 14px;
    }
  }
}

// COMMON
.w-100{
  width: 100%;
}

.ant-enter-info{
  margin-top: 25px;
}

.btn-add-subtitle{
  margin:0px 0px 0px 10px;
}

.btn-add-episode{
  text-align: left;
  margin: 10px 0px 0px 0px;
}

.gx-main-content-wrapper{
  position: relative;
}

.gx-custom-header-notifications{
  margin-left: auto;
}
.ant-table-wrapper{
  zoom: 0 !important;
}

.gx-app-login-wrap::-webkit-scrollbar{
  width: 8px;
}


.wellcome-page{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


// .ant-select-search{
//   width: 100%;
// }

// CREATE CATEGORY TYPE
.category-type .ant-radio-wrapper{
  margin-top: 10px;
  margin-bottom: 3px;
}

.category-group{
  margin-top: 10px;
  margin-bottom: 10px;
}

.btn-add-interactive-part{
  text-align: left;
}

// MODAL ANT
.ant-modal-body{
  padding: 10px;
}


// TABLE HEADER

.ant-card-body{
  padding: 10px;
  .table-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    &-tools{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .table-header-search{
          width: 100%;
      }
    }
    .ant-select-limit-paging{
      -ms-flex-item-align: start;
          align-self: flex-start;
          margin-bottom: 20px;
    }
  }
}
@media screen and (min-width: 576px) {
  .ant-card-body{
    padding: 10px;
    .table-header {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      &-tools{
        .table-header-search{
            width: 250px;
        }
      }
      .ant-select-limit-paging{
        -ms-flex-item-align: unset;
            -ms-grid-row-align: unset;
            align-self: unset;
            margin-bottom: unset;
      }
    }
  }
}

// MEDIA QUERY COMMON
@media screen and (max-width: 360px){
  .btn-add-subtitle{
    margin: 10px 0px 0px 0px;
    display: block;
  }
}


@media screen and (max-width: 575px){
  input {
    font-size: 16px;
  }

  .gx-app-login-main-content{
    margin-bottom: 110px;
  }

  .ant-layout-header{
    position: fixed;
    top: 0px;
    width: 100%;
  }
  .gx-main-content-wrapper {
      padding: 0px 10px 10px 10px;
      position: relative;
      margin-bottom: 80px;
      margin-top: 100px;
  }

  // @supports (-webkit-touch-callout: none) {
  //   /* CSS specific to iOS devices */
  //   .gx-main-content-wrapper {
  //       margin-top: 160px !important;
  //   }
  // }


  .gx-popover-custom{
    top: 54px;
    transform-origin: 297.859px -4px;
    width: 53%;
    right: 5px;
    position: absolute;
    text-align: center;
    left: unset !important;
  }
  .gx-popover-custom.gx-popover-horizantal .ant-popover-inner-content{
      width: 100% !important;
  }
  .gx-popover-custom.gx-popover-horizantal .gx-user-popover{
    padding: 0px 0px;
  }
  .gx-popover-custom.gx-popover-horizantal .gx-user-popover li{
    padding: 10px 15px;
  }
}
@media screen and (max-width: 991px){
  .gx-card-body, .gx-card .ant-card-body {
      padding: 10px;
  }
  .gx-card .ant-card-head {
      padding: 0px 13px;
  }
}


// RESPONSIVE CMS
// .ant-layout-header--hide{
//   margin-top: -50px;
// }

.ant-table-body{
  scrollbar-width: thin;
}
.ant-table-body::-webkit-scrollbar{
  z-index: 2;
  height: 9px;
  width: 7px;
}

.ant-table-header {
  overflow: hidden !important;
  margin-bottom: 0px !important;
}

.ant-table-header{
  padding-right: 7px !important;
}

@-moz-document url-prefix() {
  .ant-table-header{
    padding-right: 8px !important;
  }
}

.ant-table-body-inner{
  max-height: 79vh !important;
}


@media screen and (min-width: 576px) {

  .ant-layout-header--hide{
    margin-top: -72px;
  }

  .btn-add-interactive-part{
    text-align: right;
  }

  .btn-add-episode{
    text-align: right;
    margin: 0px 0px 0px 0px;
  }


}

@media screen and (min-width: 768px) {
  .ant-select-search{
    max-width: 280px;
    width: 280px;
  }

  // .ant-enter-info{
  //   margin-top: unset;
  // }

}


// vong quay may man
.wrapper-spinner .spinner{
  position: relative;
  // height: 500px;
  height: 490px;
  margin-top: 20px;
  width: 100%;
  min-height: 200px;
  min-width: 200px;
  border-radius: 0%;
  box-shadow: 0px 0px 160px rgba(17, 17, 17, 0.25);
  overflow: hidden;
  border-radius: 26px 26px 15px 15px;
  background-color: #9f0018;
  // background-color: #f5222d;
}
// .wrapper-spinner .spinner-apricot{
//   position: absolute;
//   z-index: 2;
//   bottom: -43px;
//   right: -51px;
// }

// .wrapper-spinner .spinner-apricot-1{
//   position: absolute;
//   z-index: 2;
//   bottom: 6px;
//   left: 23px;
// }
// .wrapper-spinner .spinner-new-year{
//   position: absolute;
//   z-index: 2;
//   left: 0px;
//   width: 100%;
//   top: 161px;
// }
.wrapper-spinner .spinner-skies{
  position: absolute;
  z-index: 2;
  top: 24%;
  left: 5%;
  width: 100%;
}

.wrapper-spinner .spinner-heart{
  position: absolute;
  z-index: 2;
  top: 37%;
  left: 15%;
  width: 100%;
}

.wrapper-spinner .spinner-sky{
  position: absolute;
  z-index: 2;
  bottom: -50px;
  width: 100%;
  left: 42px;
  transform: scale(1.5);
}

.wrapper-spinner .spinner-logo{
  position: absolute;
  z-index: 2;
  left: 50%;
  width: 150px;
  bottom: 29px;
  transform: translateX(-50%);
}


.wrapper-spinner .spinner::after{
  content: '';
  background-color: #9f0018;
  width: 100%;
  display: block;
  height: calc(100% / 2);
  position: absolute;
  bottom: 0;
  // background-image: url(../assets/images/yellow-apricot-2.png);
  // background-position: right bottom, left top;
  // background-repeat: no-repeat, repeat;
}
.wrapper-spinner .spinner-body{
  position: absolute;
  border-top: 160px solid transparent;
  border-right: 140px solid #9f0018;
  border-bottom: 170px solid #9f0018;
  border-left: 140px solid #9f0018;
  // #7FC2C2
  z-index: 2;
  width: 100%;
}

// .spinner-body::after{
//   content: "";
//   position: absolute;
//   background-color: #32cd32;
//   width: 100%;
//   height: 200px;
// }

.heart{
  display: block;
  width: 90px;
  height: 90px;
  animation: pulse 1s infinite;
  position: absolute;
  top: 25%;
  left: 36%;
  z-index: 3;
  cursor: pointer;
  position: relative;
}
.heart .heart-full{
  stroke: white;
  stroke-width: 30;
  fill: #9f0018;
  filter:  drop-shadow(0 0 8px white);
  -webkit-filter: drop-shadow(0 0 8px white);
}


@keyframes pulse {
  0%, 20% {
    transform:  scale(1);
  }
  30% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  70%, 100% {
    transform: scale(1);
  }
}


.spinner-start-button{
  position: absolute;
  top: 35%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  height: 90px;
  width: 90px;
  border: 1px solid white;
  border-radius: 6%;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #f5f5f5;
  background-color: #9f0018;
  outline: none;
  user-select: none;

  transition:
    height .1s ease-in,
    width .2s ease-in,
    color .1s ease-in,
    background-color .1s ease-in;
}

.spinner-start-button:hover{
  height: 90px;
  width: 90px;
  background-color: #9f0018;
  box-shadow: 0px 0px 44px rgba(17, 17, 17, 0.25);
}
.spinner-start-button:active{
  height: 90px;
  width: 90px;
  color: #9f0018;
  background-color: #9f0018;
  box-shadow: none;
  // animation: shaking .15s ease-in-out infinite;
}
.spinner-input{
  position: absolute;
  bottom: 37px;
  left: 50%;
  padding: 2px 10px;
  transform: translatex(-50%);
  font-family: 'Dosis', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: #7FC2C2;
  background:  #f5f5f5;
  border-radius: 36px;

  text-align: center;
  outline: none;
  border: none;

  z-index: 2;

  transition: .1s ease-in;
}
.spinner-input:hover{
  color: #8CD0D0;
}
.spinner-input:focus {
  color: #459A9A;
}

.spinner-plate{
  position: absolute;
  // top: 18px;
  top: 30px;
  // height: 240px;
  height: 329px;
  width: 100%;
  // background-color: #7FC2C2;
  background-color: white;
}

.spinner-item{
  position: absolute;
  top: 5;
  left: 50%;
  transform: translatex(-50%);
  height: calc(242px - 40px);
  padding-top: 30px;
  font-family: 'Dosis', sans-serif;
  font-weight: bold;
  font-size: 20px;
  // color: white;
  color: black;
  text-align: center;
  transform-origin: 50% 100%;
  z-index: 1;
}
.spinner-item:before{
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translatex(-50%);
  // border-top: 140px solid #ff6b00d9;
  // border-right: 140px solid transparent;
  // border-bottom: 97px solid transparent;
  // border-left: 140px solid transparent;
  border-top: 170px solid white;
  border-right: 170px solid transparent;
  border-bottom: 97px solid transparent;
  border-left: 170px solid transparent;
  z-index: -1;
}
.spinner-item:nth-child(even)::before{
  border-top: 180px solid white;
}

.spinner-item-left {
  transform: translatex(-50%) rotate(-90deg);
}

.spinner-item-top {
    transform: translatex(-50%) rotate(0deg);
}

.spinner-item-right {
    transform: translatex(-50%) rotate(90deg);
}

.spinner-item-bottom {
    transform: translatex(-50%) rotate(180deg);
}
// .shaking{
//   -webkit-animation: shaking 2s ease infinite;
//           animation: shaking 2s ease infinite;
//   display: inline-block;
// }
@keyframes shaking{
  0% {
      transform: rotate(0) scale(1) skew(1deg);
      -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
      transform: rotate(-1.5deg) scale(1) skew(1deg);
      -webkit-transform: rotate(-1.5deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(1.5deg) scale(1) skew(1deg);
      -webkit-transform: rotate(1.5deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-1.5deg) scale(1) skew(1deg);
      -webkit-transform: rotate(-1.5deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(1.5deg) scale(1) skew(1deg);
      -webkit-transform: rotate(2deg) scale(1) skew(1deg);
  }
  50% {
    transform:rotate(0) scale(1) skew(1deg);
      -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform:rotate(0) scale(1) skew(1deg);
      -webkit-transform: rotate(0) scale(1) skew(1deg);
  }
}

// @keyframes shaking {
//   0% {
//     top: calc(50% + 1px);
//     left: calc(50% - 1px);
//   }
//   25% {
//     top: calc(50% - 1px);
//     left: calc(50% + 1px);
//   }
//   50% {
//     top: calc(50% + 1px);
//     left: calc(50% + 1px);
//   }
//   75% {
//     top: calc(50% - 1px);
//     left: calc(50% - 1px);
//   }
// }
.spinner-plate-spin{
  // ease-out
  animation: spinning 5s ease-out;
  border-radius: 50%;
}
.spinner-plate-spin > .spinner-item:before{
  content: '';
  border-radius: 50%;
  border-top: 225px solid white;
  border-right: 180px solid transparent;
  border-bottom: 180px solid transparent;
  border-left: 180px solid transparent;
}
@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1800deg);
  }
}
.wrapper-random-number{
  margin-bottom: 50px;
}
.result-title{
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.result-random{
  border: 1px solid black;
  padding: 10px;
  max-width: 300px;
  margin-bottom: 20px;
}
.result-random-header{
  font-weight: bold;
  margin-bottom: 5px;
}
.result-random-header .result-random-stt{
  display: inline-block;
  min-width: 80px;
}
.result-random-header .result-random-code{
  display: inline-block;
  min-width: 150px;
}

.result-random-body{

}
.result-list-item{
  padding: 4px 0px;
}
.result-list-item .result-item-index{
  display: inline-block;
  min-width: 80px;
}
.result-list-item .result-item-event-code{
  display: inline-block;
  min-width: 150px;
}
.switch-ignore-winner{
  margin-top: 10px;
}
@media screen and (max-width: 320px) {
  .spinner{
    height: 410px;
  }
  .spinner-item{
    font-size: 16px;
  }
  .spinner-start-button{
    top: 42%;
  }
  .spinner-item:before{
    content: '';
    border-top: 180px solid white;
    border-right: 180px solid transparent;
    border-bottom: 180px solid transparent;
    border-left: 180px solid transparent;
  }
  .spinner-body{
    border-top: 140px solid transparent;
    border-right: 85px solid #9f0018;
    border-bottom: 140px solid #9f0018;
    border-left: 85px solid #9f0018;
  }

}
@media screen and (min-width: 576px) {
  .spinner{
    max-width: 320px;
  }
  .spinner-plate{
    max-width: 320px;
  }

  .wrapper-spinner{
    display: flex;
    justify-content: center;
  }

  .spinner-item:before{
    content: '';
    border-top: 180px solid white;
    border-right: 180px solid transparent;
    border-bottom: 180px solid transparent;
    border-left: 180px solid transparent;
  }
  .spinner-body{
    border-top: 204px solid transparent;
    border-right: 160px solid #9f0018;
    border-bottom: 200px solid #9f0018;
    border-left: 160px solid #9f0018;
  }
  .spinner-plate{
    height: 300px;
  }

  .spinner-start-button{
    top: 38%;
  }

  .spinner-item{
    padding-top: 25px;
    font-size: 23px;
  }

  .spinner-start-button{
    height: 100px;
    width: 100px;
    font-size: 20px;
  }
  
  .spinner-start-button:hover{
    height: 100px;
    width: 100px;
  }
  .spinner-start-button:active{
    height: 100px;
    width: 100px;

  }
}